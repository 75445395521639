<template>
  <el-row :gutter="20">
    <div class="asset-picker__modules-list">
      <el-table v-if="modulesList.length > 0"
                :data="modulesList"
                stripe
                :default-sort = "{prop: 'name', order: 'ascending'}"
                style="width: 100%"
                :max-height="height"
                @row-click="onRowClick">

        <el-table-column width="45" fixed>
          <template #default="scope">
            <el-button v-if="scope.row.id"
                       :class="{ active: scope.row.id === selectedModule }" />
          </template>
        </el-table-column>

        <el-table-column width="120"
                         prop="name"
                         label="Name"
                         sortable />

        <el-table-column prop="description"
                         label="Description"
                         sortable />

        <el-table-column width="120"
                         prop="country"
                         label="Country"
                         sortable />

        <el-table-column width="120"
                         prop="product"
                         label="Product"
                         sortable />

      </el-table>
    </div>

    <div class="asset-picker__fader" />

    <div class="asset-picker__apply-button">
      <el-button v-if="modulesList.length > 0"
                 @click="onApply"
                 :disabled="!selectedModule">
        Apply
      </el-button>
    </div>
  </el-row>
</template>

<style lang="scss">
.asset-picker {
  &__modules-list {
    width: 100%;

    .el-table {
      &__row--striped {
        background-color: #f9f9f9;
      }

      &::before, &__fixed::before {
        height: 0;
      }

      .ascending .sort-caret.ascending {
        border-bottom-color: #444;
      }

      .descending .sort-caret.descending {
        border-top-color: #444;
      }

      .el-button {
        border-radius: 50%;
        width: 15px;
        min-width: 15px;
        height: 15px;
        min-height: 15px;
        padding: 0;

        &.active {
          border: none;
          outline: none;
          background: #4050b5;
        }
      }

      table, tr, td {
        border: none;
      }

      tr:last-of-type {
        background-color: #fff;

        td {
          background: #fff !important;
        }
      }

      th {
        color: #444;
      }
    }
  }

  &__fader {
    position: fixed;
    z-index: 9;
    bottom: 0;
    left: 0;
    width: calc(100% - 1px);
    height: 64px;
    background: #fff;
    opacity: .75;
  }

  &__apply-button {
    position: fixed;
    z-index: 10;
    bottom: 24px;
    right: 24px;

    .el-button {
      color: #fff;
      background-color: #4050b5;
      border: none;

      &:hover {
        background-color: #2e3a85;
      }

      &.is-disabled,
      &.is-disabled:hover {
        color: #53565a;
        background-color: #e0e0e0;
      }
    }
  }
}
</style>

<script>
import Client from '../mixins/Client.vue'
import Message from '../mixins/Message.vue'
import Debounce from '../mixins/Debounce.vue'

export default {
  name: "Modules",

  mixins: [
    Client,
    Message,
    Debounce
  ],

  props: {
    input: {
      type: String,
      required: true
    },
    height: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      modulesList: [],
      selectedModule: null
    }
  },

  watch: {
    input: {
      // Force a watcher's callback to be executed immediately
      immediate: false,
      handler(value) {
        this.searchForContentModules(value)
      }
    }
  },

  methods: {
    /**
     * todo think about improving regex to use prefix from content module vault properties
     * @param {string} value
     * @return {void}
     */
    searchForContentModules (value) {
      this.debounce(() => {
        if (/^[Vv]..\d+$/.test(value)) {
          this.getContentModuleById(value)
        } else if (value.length === 0) {
          this.getContentModules()
        } else if (value.length >= 3){
          this.getContentModulesByKeyword(value)
        } else {
          this.$emit('stoppedLoading')
        }
      })
    },

    /**
     * Fetch list of content modules
     * todo think about adding lazy loading/pagination
     *
     * @returns {void}
     */
    getContentModules () {
      this.$emit('loading')
      this.queue.add(() => this.client.get('/request/content-modules')
        .then((response) => {
          this.modulesList = this.mapListData(response.data)
          this.$emit('update', this.modulesList)
        }))
        .catch(() => {
          this.$emit('update', [])
        })
    },

    /**
     * @param {string} keyword
     * @returns {void}
     */
    getContentModulesByKeyword (keyword) {
      this.$emit('loading')
      this.queue.add(() => this.client.post('/request/content-modules-by-keyword', {
        data: {
          keyword: keyword
        }
      })
        .then((response) => {
          this.modulesList = this.mapListData(response.data)
          this.$emit('update', this.modulesList)
        }))
        .catch(() => {
          this.$emit('update', [])
        })
    },

    /**
     * @param {string} id
     * @returns {void}
     */
    getContentModuleById (id) {
      this.$emit('loading')
      this.queue.add(() => this.client.post('/request/content-module-by-id', {
        data: {
          id: id
        }
      }))
        .then((response) => {
          this.modulesList = this.mapListData(response.data)
          this.$emit('update', this.modulesList)
        })
        .catch(() => {
          this.$emit('update', [])
        })
    },

    /**
     * Gets Content Module details based on module ID
     * @param {string} id
     */
    getContentModuleDetails (id) {
      return this.client.post('/request/content-module-details', {
        data: {
          id: id
        }
      })
    },

    /**
     * Maps data for list view
     * @param {array} data
     */
    mapListData (data) {
      const list = data.map(doc => {
        return {
          id: doc.id,
          name: doc.name,
          description: doc.description || '-',
          country: doc.country || '-',
          product: doc.product || '-',
        }
      })

      // we are adding one empty row
      // to be able to scroll out
      list.push({
        id: null,
        name: null,
        description: null,
        country: null,
        product: null
      })

      return list
    },

    /**
     * Check radio button ans set selected module
     * @param {object} row
     * @returns {void}
     */
    onRowClick (row) {
      this.selectedModule = row.id
    },

    /**
     * On document select:
     * If image picker - fetch image file and post message it to api briefing service
     * If document picker - post message id to api briefing service action
     *
     * @returns {void}
     */
    onApply () {
      this.$emit('loading')

      this.getContentModuleDetails(this.selectedModule)
        .then(response => {
          const moduleData = response.data

          if (moduleData) {
            this.postMessage(
                'executeBriefingServiceAction',
                JSON.parse(JSON.stringify(moduleData))
            )
          } else {
            this.$emit('update', null)

            setTimeout(() => {
              this.getContentModules()
            }, 2000)
          }
        })
    }
  },

  mounted () {
    this.getContentModules()
  },

}
</script>
