<template>
  <div class="app-container">
    <el-button class="close" @click="onClose()" size="mini">
      <i class="el-icon-close"></i>
    </el-button>

    <picker
        v-if="pickerType"
        :key="pickerType"
        :picker-type="pickerType"
        :picker-labels="pickerLabels"
        :documents="documents"
        :allowedStates="allowedStates"
        :images="images"
    />
  </div>
</template>

<style lang="scss">
body {
  margin: 0;
  font-family: Inter, Roboto, Oxygen, Fira Sans, Helvetica Neue, sans-serif;;
}

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  border: none;

  i {
    font-size: 18px;
    color: #444;
  }
}
</style>

<script>
import Picker from './components/Picker'
import Client from './mixins/Client.vue'
import { development } from './fixtures.js'

export default {
  name: "App",
  components: {
    Picker,
  },
  mixins: [
    Client
  ],
  data () {
    return {
      pickerType: null,
      pickerLabels: null,
      documents: {
        allowedTypes: [],
        allowedSubTypes: [],
      },
      images: {
        allowedTypes: [],
        allowedSubTypes: [],
      },
      allowedStates: []
    }
  },
  methods: {
    /*
    * Handles post message data coming from api briefing service
    */
    messageHandler (e) {
      if (e.data.command === 'briefingServicePostData') {
        this.pickerType = e.data.content.type
        this.pickerLabels = e.data.content.configuration.vaultAssetPickerLabels[this.pickerType]
        Object.assign(this.documents, e.data.content.configuration?.documents)
        Object.assign(this.images, e.data.content.configuration?.images)

        e.data.content.configuration?.approvedStates.forEach(state => {
          this.allowedStates.push(state.value)
        })
      }
    },

    /*
    * Post message to api briefing service to close the frame
    */
    onClose() {
      window.parent.postMessage({
        command: 'rejectBriefingServiceResult',
        argument: 'closed'
      }, '*')
    },

    setupDevelopment () {
      this.client.post('/initialize', {
        configuration: development.configuration
      }).then (() => {
        this.pickerType = development.pickerType
      })
    }
  },

  created () {
    window.addEventListener('message', this.messageHandler)

    // for local development
    if (location.origin === 'http://localhost:4000') {
      this.setupDevelopment()
    }
  },

  beforeUnmount () {
    window.removeEventListener('message', this.messageHandler)
  }
};
</script>
