import { createStore } from 'vuex'

export default createStore({
  state: {
    moduleMapping: null,
    moduleMappingShort: null,
    previewsSwitchEnabled: false,
  },
  getters: {
    getModuleMapping (state) {
      return state.moduleMapping
    },
    getModuleMappingShort (state) {
      return state.moduleMappingShort
    },
    getPreviewsSwitchEnabled (state) {
      return state.previewsSwitchEnabled
    },
  },
  mutations: {
    setModuleMapping (state, mapping) {
      state.moduleMapping = mapping
    },
    setModuleMappingShort (state, mapping) {
      state.moduleMappingShort = mapping
    },
    setPreviewsSwitchEnabled (state, payload) {
      state.previewsSwitchEnabled = payload
    },
  },
  actions: {
    setPreviewsSwitchEnabled ({commit}, payload) {
      commit('setPreviewsSwitchEnabled', payload)
    },
  }
})
