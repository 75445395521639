<template>
  <el-card
    class="document-item"
    shadow="hover"
    :body-style="{ padding: '0px' }">
    <div class="document-item__container">
      <div class="document-item__header-with-image">
        <div class="document-item__header-with-image__img-holder" :thumb="showPreview ? setImageThumbnail() : ''">
          <span
            v-if="imageLoading"
            class="document-item__header-with-image__loader"
            v-loading="true">
          </span>
          <img
            v-if="!showPreview"
            class="border fallback"
            :src="icon" />
          <img
            v-if="showPreview && !imageLoading"
            class="border"
            :class="{ 'fallback': image?.fallback }"
            :src="image?.src" />
        </div>
        <span class="document-item__header__title">{{ item.name }}</span>
      </div>

      <div class="document-item__bottom">
        <span><strong>Name:</strong> {{ item.name }}</span>
        <span><strong>Type:</strong> {{ item.type }}</span>
        <span><strong>Status:</strong> {{ item.status }}</span>
        <span><strong>Created:</strong> {{ getDocumentDate(item) }}</span>
      </div>
    </div>
  </el-card>
</template>

<style lang="scss">
.document-item {
  $border-color: #ebeef5;

  margin-bottom: 20px;
  cursor: pointer;

  &__container {
    padding: 14px;
  }

  &__header {
    display: flex;
    align-items: center;

    &__title {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.75);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    img {
      margin-right: 12px;
    }
  }

  &__header-with-image {
    &__loader {
      display: block;
      width: 100%;
      height: 100px;
    }

    img {
      width: 100%;
    }

    &__img-holder {
      height: 100px;
      margin-bottom: 20px;

      img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        display: block;
        margin: 0 auto;

        &.border {
          border: 1px solid $border-color;
        }

        &.fallback {
          width: 100%;
        }
      }
    }
  }

  &__bottom {
    margin-top: 13px;
    line-height: 12px;

    span {
      display: block;
      line-height: normal;
      font-size: 13px;
      color: #999;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>

<script>
import Thumbnail from '../mixins/Thumbnail.vue'
import { mapGetters } from 'vuex'

export default {
  name: "DocumentItem",

  mixins: [
    Thumbnail
  ],

  props: {
    item: {
      type: Object,
      required: true
    },
    isImagePicker: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      icon: this.getImageThumbnailFallback(this.item),
      image: null
    }
  },

  computed: {
    ...mapGetters({
      previewsSwitchEnabled: 'getPreviewsSwitchEnabled'
    }),
    imageLoading() {
      return Boolean(this.showPreview && !this.image)
    },
    showPreview() {
      return (this.previewsSwitchEnabled || this.isImagePicker)
    }
  },

  methods: {
    /**
     * Returns document creation date in nice format
     *
     * @returns {string}
     */
    getDocumentDate (document) {
      const date = new Date(document.creation_date)
      return date.toLocaleString()
    },

    /**
     * Returns image thumbnail fetched from Vault if not fetched yet
     *
     * @returns {void}
     */
    setImageThumbnail() {
      if (this.image) {
        return
      }
      
      this.getImageThumbnail(this.item)
        .then(image => {
          this.image = image
        })
    }
  },
}
</script>
