<script>
import axios from 'axios'
import PQueue from 'p-queue'

const BASE_URL = process.env.NODE_ENV === 'development'
    ? 'http://localhost:11100/'
    : location.origin

export default {
  data() {
    return {
      client: null,
      queue: null
    }
  },

  methods: {
    /**
     * Set Axios client connection to the server side
     * @return {void}
     */
    setClient () {
      this.client = axios.create({
        baseURL: BASE_URL
      })
    }
  },

  created() {
    this.setClient()
    this.queue = new PQueue({concurrency: 1})
  }
}
</script>
