<template>
  <el-row :gutter="20">
    <el-col
      v-for="item in documentList"
      :key="item.id"
      :span="6"
    >
      <document-item
        :item="item"
        :isImagePicker="isImagePicker"
        @click="onDocumentSelect(item.id)"
      />
    </el-col>
  </el-row>
</template>

<script>
import Client from '../mixins/Client.vue'
import Message from '../mixins/Message.vue'
import Debounce from '../mixins/Debounce.vue'
import DocumentItem from './DocumentItem'

export default {
  name: "Documents",

  mixins: [
    Client,
    Message,
    Debounce
  ],

  components: {
    'document-item': DocumentItem,
  },

  props: {
    type: {
      type: String,
      required: true
    },
    input: {
      type: String,
      required: true
    },
    documents: {
      type: Object,
      required: true,
    },
    images: {
      type: Object,
      required: true,
    },
    allowedStates: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      documentList: [],
      documentStorage: [],
      allResults: false,
    }
  },

  computed: {
    isImagePicker () {
      return this.type === 'image'
    }
  },

  watch: {
    input: {
      // Force a watcher's callback to be executed immediately
      immediate: true,
      handler(value) {
        this.searchForDocuments(value)
      }
    }
  },

  methods: {
    /**
     * Fetch list of recent documents
     *
     * @returns {void}
     */
    getRecentDocuments () {
      this.queue.add(() => this.client.get('/request/recent-documents')
          .then((response) => {
            let documents = response.data.data
            documents = this.filterDocuments(documents)

            this.documentList = documents
            this.$emit('update', this.documentList)
          })
          .catch(() => {
            this.$emit('update', [])
          })
      )
    },

    /**
     * Fetch list of documents based on the search keyword
     *
     * @returns {void}
     */
    getDocuments (keyword) {
      this.queue.add(() => this.client.post('/request/documents', {
            data: {
              keyword: keyword
            }
          })
              .then((response) => {
                let documents = this.documentStorage.concat(response.data.data)
                documents = this.filterDocuments(documents)

                this.documentList = documents
                this.documentStorage = []

                this.$emit('update', this.documentList)
              })
              .catch(() => {
                this.$emit('update', [])
              })
      )
    },

    /**
     * Fetch document based on provided ID
     *
     * @returns {void}
     */
    getDocumentById (id) {
      this.queue.add(() => this.client.post('/request/document-by-id', {
            data: {
              id: id
            }
          })
              .then((response) => {
                let document = response.data.data

                // filterDocuments is using an array of objects
                document = this.filterDocuments([document])

                // VQL response has different format
                // so we need to adapt it
                this.documentList = document[0].id
                    ? document
                    : []

                this.$emit('update', this.documentList)
              })
              .catch(() => {
                this.$emit('update', [])
              })
      )
    },

    filterDocumentsByStatus (documents) {
      let filteredDocuments = documents
      const allowedStates = this.getIncludeList(this.allowedStates)

      if (allowedStates.length) {
        filteredDocuments = filteredDocuments.filter(doc => {
          return allowedStates.includes(doc.status.toLowerCase())
        })
      }

      return filteredDocuments
    },

    filterDocumentsBySubtype (documents) {
      let filteredDocuments = documents
      const allowedImagesSubTypes = this.getIncludeList(this.images.allowedSubTypes)
      const excludedImagesSubTypes = this.getExcludeList(this.images.allowedSubTypes)
      const allowedDocumentsSubTypes = this.getIncludeList(this.documents.allowedSubTypes)
      const excludedDocumentsSubTypes = this.getExcludeList(this.documents.allowedSubTypes)

      if (this.isImagePicker && excludedImagesSubTypes.length) {
        filteredDocuments = filteredDocuments.filter( doc => {
          return !excludedImagesSubTypes.includes(doc?.subtype?.toLowerCase()) && doc?.filename
        })
      }

      if (this.isImagePicker && allowedImagesSubTypes.length) {
        filteredDocuments = filteredDocuments.filter( doc => {
          return allowedImagesSubTypes.includes(doc?.subtype?.toLowerCase()) && doc?.filename
        })
      }

      if (!this.isImagePicker && excludedDocumentsSubTypes.length) {
        filteredDocuments = filteredDocuments.filter( doc => {
          return !excludedDocumentsSubTypes.includes(doc?.subtype?.toLowerCase()) && doc?.filename
        })
      }

      if (!this.isImagePicker && allowedDocumentsSubTypes.length) {
        filteredDocuments = filteredDocuments.filter( doc => {
          return allowedDocumentsSubTypes.includes(doc?.subtype?.toLowerCase()) && doc?.filename
        })
      }

      return filteredDocuments
    },

    filterDocumentsByAllowedTypes (documents) {
      let filteredDocuments = documents
      const allowedTypes = this.getIncludeList(this.documents.allowedTypes)
      const excludedTypes = this.getExcludeList(this.documents.allowedTypes)

      if (excludedTypes.length) {
        filteredDocuments = filteredDocuments.filter(doc => {
          return !excludedTypes.includes(doc.status.toLowerCase())
        })
      }

      if (allowedTypes.length) {
        filteredDocuments = filteredDocuments.filter(doc => {
          return allowedTypes.includes(doc.status.toLowerCase())
        })
      }

      return filteredDocuments
    },

    filterDocuments (documents) {
      let filteredDocuments = documents
      filteredDocuments = this.filterDocumentsByStatus(filteredDocuments)
      filteredDocuments = this.filterDocumentsBySubtype(filteredDocuments)

      if ( !this.isImagePicker ) {
        filteredDocuments = this.filterDocumentsByAllowedTypes(filteredDocuments)
      }
      return filteredDocuments
    },

    /**
     * @param {string} value
     * @return {void}
     */
    searchForDocuments (value) {
      this.debounce(() => {
        if (/^\d+$/.test(value)) {
          this.getDocumentById(value)
        } else if (value.length === 0) {
          this.getRecentDocuments()
        } else if (value.length >= 3) {
          this.getDocuments(value)
        } else {
          this.$emit('stopLoading')
        }
      })
    },

    /**
     * On document select:
     * If image picker - fetch image file and post message it to api briefing service
     * If document picker - post message id to api briefing service action
     *
     * @returns {void}
     */
    onDocumentSelect (documentId) {
      this.$emit('loading')

      if (this.isImagePicker) {
        this.client.post('/request/document-file', {
          data: {
            id: documentId
          }
        }).then((response) => {
          this.postMessage('resolveBriefingServiceResult', response.data)
        })
      } else {
        this.client.post('/request/document-by-id', {
          data: {
            id: documentId
          }
        })
          .then((response) => {
            this.postMessage('executeBriefingServiceAction', response.data.data)
          })
      }
    },

    getIncludeList(list) {
      const filteredList = list.map((item) => {
        if (!item.includes('!')) {
          return item.toLowerCase()
        }
      })

      return filteredList.filter(item => !!item)
    },

    getExcludeList(list) {
      const filteredList = list.map((item) => {
        if (item.includes('!')) {
          return item.replace('!', '').toLowerCase()
        }
      })

      return filteredList.filter(item => !!item)
    },
  },

  mounted () {
    if (!this.input.length) {
      this.getRecentDocuments()
    }
  },

}
</script>
