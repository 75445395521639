export const development = {
  // what to show: document, module
  'pickerType': 'module',

  'configuration': {
    'connection': {
      // do not change
      'url': 'http://vault-connector.showcase.test',

      // a string array of features/version changes it should apply
      // claimDataModel will use new vault claim data model
      'features': ['claimDataModel'],

      // id in vault_instances
      'vault': 'vap',

      // related key in consumers
      'key': '123'
    },
    'approvedStates': [
      // filter response data, could be needed to be changed for local testing
      // {
      //   'key': 'approved_state__c',
      //   'value': 'Approved for Use'
      // }
    ],
    'documents': {
      'allowedSubTypes': [
        '!Images'
      ]
    },
    'images': {
      'allowedSubTypes': [
        'Images'
      ]
    },
    'claimMapping': {
      'referenceId': 'link_target__v'
    },
    'claimReferenceMapping': {
      'documentVersionId': 'document_version_id__v',
      'anchorId': 'anchor_id__sys',
      'anchorPage': 'anchor_page__sys',
      'anchorTitle': 'anchor_title__sys',
      'name': 'name__v',
      'referenceId': 'id',
      'status': 'status__v.0',
      'type': 'suggestedlink_link_target_type__v.0',
      'createdAt': 'created_date__v',
      'createdBy': 'created_by__v',
      'modifiedAt': 'modified_date__v',
      'modifiedBy': 'modified_by__v'
    },
    'moduleVaultAssetMapping': {
      'asset': 'asset__v',
      'assets': 'content_module_assets',
      'base64': 'base64',
      'category': 'category__v.0',
      'claim': 'claim__v',
      'claimId': 'claim__v.id',
      'claimText': 'claim__v.match_text__sys',
      'claimVariantText': 'match_text__sys',
      'claimVariants': 'claim__v.match_text_variations',
      'contentModuleAssetType': 'object_type__v',
      'country': 'country__v.name__v',
      'description': 'description__v',
      'externalId': 'id',
      'label': 'label__v',
      'link': 'link__sys',
      'mandatory': 'must_use__v',
      'name': 'name__v',
      'previewSrc': 'base64Thumbnail',
      'product': 'product__v.name__v',
      'ruleContent': 'rule_content__v',
      'ruleType': 'rule_type__v.0.label',
      'rulesetRules': 'content_module_ruleset__v.ruleset_rules',
      'rulesetSummary': 'ruleset_summary__v',
      'state': 'state__v',
      'text': 'text_element_content__v',
      'textType': 'text_element_type__v.0.label',
      'usage': 'usage_guidelines__v',
      'vaultAssetName': 'asset__v.name__v',
      'vaultDocumentId': 'asset__v.id',
      'vaultDocumentVersion': 'asset__v.version_id'
    },
    'moduleVaultMapping': {
      'country': 'country__vr.name__v',
      'description': 'description__v',
      'id': 'id',
      'name': 'name__v',
      'product': 'product__vr.name__v'
    },
    'vaultAssetPickerLabels': {
      'document': {
        'headline': 'Search Veeva Vault Documents',
        'noResults': 'There is nothing to see here, seems like we found no documents. Please enter a search term to dig deeper.',
        'noResultsOnSearch': 'These aren\'t the documents you?re looking for! Please try another search term to find something fitting.',
        'notAvailable': 'This document isn\'t available anymore.',
        'searchPlaceholder': 'Start typing to search for documents...'
      },
      'image': {
        'headline': 'Search Veeva Vault Images',
        'noResults': 'There is nothing to see here, seems like we found no images. Please enter a search term to dig deeper.',
        'noResultsOnSearch': 'These aren\'t the images you?re looking for! Please try another search term to find something fitting.',
        'notAvailable': 'This image isn\'t available anymore.',
        'searchPlaceholder': 'Start typing to search for images...'
      },
      'module': {
        'headline': 'Search Veeva Vault Modules',
        'noResults': 'There is nothing to see here, seems like we found no modules. Please enter a search term to dig deeper.',
        'noResultsOnSearch': 'These aren\'t the modules you?re looking for! Please try another search term to find something fitting.',
        'notAvailable': 'This module isn\'t available anymore.',
        'searchPlaceholder': 'Start typing to search for modules...'
      }
    },
    'claimDataModel': {
      'contentModule': {
        'name': 'name__v',
        'id': 'id',
        'state': 'state__v',
        'category': 'category__v.0',
        'description': 'description__v',
        'guidelines': 'usage_guidelines__v',
        'product': 'product__vr.name__v',
        'country': 'country__vr.name__v',
        'additionalFields': {},
        'asset': {
          'name': 'name__v',
          'id': 'id',
          'type': 'object_type__vr.name__v',
          'textElementType': 'text_element_type__v',
          'mandatory': 'must_use__v',
          'label': 'label__v',
          'text': 'LONGTEXT(text_element_content__v)',
          'link': 'link__sys',
          'summary': 'ruleset_summary__v',
          'rulesetId': 'content_module_ruleset__vr.id',
          'assetName': 'asset__vr.name__v',
          'version': 'asset__vr.version_id',
          'assetId': 'asset__vr.id',
          'claimId': 'claim__vr.id',
          'claimText': 'claim__vr.match_text__sys',
          'claimInstructions': 'LONGTEXT(claim__vr.instructions_for_use__c)',
          'additionalFields': {
            // both exist only in BI Vaults
            // remove for internal Demo tp1 Vault
            'claimDisclaimers': 'LONGTEXT(claim__vr.disclaimers__c)',
            'claimFootnotes': 'LONGTEXT(claim__vr.footnotes__c)'
          }
        },
        'assetRelation': 'module_asset__vr',
        'contentModuleTable': 'content_module__v'
      },
      'contentModuleQuery': {
        'category': 'category__v'
      },
      'rulesetRules': {
        'id': 'id',
        'rule': {
          'content': 'rule_content__v',
          'type': 'rule_type__v'
        },
        'ruleRelation': 'ruleset_rules__vr',
        'rulesetTable': 'content_module_ruleset__v'
      },
      'textElementTypePicklist': 'text_element_type__v',
      'state_mapping': {
        'approved_internally_state__c': 'Reviewed Internally',
        'approved_state__c': 'Approved for Use',
        'cancelled_state__c': 'Cancelled',
        'changes_required__c': 'Changes Required',
        'content_update_completed_state__c': 'Content Update Completed',
        'draft_completed_state__c': 'Draft Completed',
        'draft_state__c': 'Draft',
        'expired_state__c': 'Expired',
        'in_review_state__c': 'In Review',
        'on_hold_state__c': 'On Hold',
        'pending_content_update_state__c': 'Pending Content Update',
        'ready_for_approval_state__c': 'Ready for Review',
        'revise_and_resubmit_state__c': 'Revise and Resubmit',
        'withdrawn_state__c': 'X - Withdrawn'
      }
    }
  }
}
