<template>
  <div class="asset-picker">
    <div class="asset-picker__top"
         ref="top">
      <el-row>
        <el-col :span="24">
          <div class="asset-picker__header">
            {{ labels.headline }}
          </div>
        </el-col>
      </el-row>

      <div class="asset-picker__filter">
        <el-divider></el-divider>

        <el-row justify="space-around">
          <el-col :span="14">
            <div class="asset-picker__filter__name">
              <el-input
                  v-model="searchInput"
                  @input="onSearchInput"
                  :placeholder="labels.searchPlaceholder">
                <template #prefix>
                  <i class="el-input__icon el-icon-search"></i>
                </template>
              </el-input>
            </div>
          </el-col>
          <div
              v-if="pickerType === 'document'"
              class="preview-enabled"
          >
            Previews enabled <el-switch class="preview-enabled__switch" @change="onPreviewSwitchChange" v-model="previewsSwitchEnabled" />
          </div>
        </el-row>

        <el-divider></el-divider>
      </div>

    </div>

    <div v-loading="loading"
         class="asset-picker__loading-mask">
      <div class="asset-picker__results"
           ref="results"
           :style="{ height: resultsHeight + 'px' }">

        <p v-if="input.length && noResults && !loading"
           class="asset-picker__results__empty__search">
          {{ labels.noResultsOnSearch }}
        </p>

        <p v-else-if="noResults && !loading"
           class="asset-picker__results__empty">
          {{ labels.noResults }}
        </p>

        <p v-else-if="notAvailable && !loading"
           class="asset-picker__results__unavailable">
          {{ labels.notAvailable }}
        </p>

        <div v-else>
          <modules v-if="pickerType === typeModule"
                   :input="input"
                   :height="resultsHeight"
                   @loading="onLoading"
                   @stoppedLoading="stopLoading"
                   @update="onUpdate" />

          <documents v-else
                     :type="pickerType"
                     :documents="documents"
                     :allowedStates="allowedStates"
                     :images="images"
                     :input="input"
                     @loading="onLoading"
                     @stopLoading="stopLoading"
                     @update="onUpdate" />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.asset-picker {
  $font-color: #606266;

  .preview-enabled {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 2;
    color: #53565a;
    font-size: 14px;
    letter-spacing: 0.14px;

    &__switch {
      margin-left: 10px;

      &.el-switch {
        --el-switch-on-color: #3f51b5;
        --el-switch-width: 42px;
        --el-switch-height: 24px;
        --el-switch-button-size: 20px;
        --el-switch-core-border-radius: 12px;
      }
    }
  }

  &__header {
    margin-top: 24px;
    text-align: center;
    font-size: 18px;
    color: #53565a;
    font-weight: bold;
  }

  &__filter {
    background-color: #f9f9f9;
    width: calc(100% - 1px);

    &__name {
      background-color: #f9f9f9;

      input {
        border: none;
        border-radius: 0;
        background-color: transparent;
        border-bottom: 2px solid #dadada;
        font-size: 16px;
        outline-color: #dadada;
      }

      i {
        font-size: 18px;
      }
    }

    &__type {
      text-align: right;
    }
  }

  &__results {
    position: relative;
    padding: 0 24px;
    margin-bottom: 24px;
    overflow-y: scroll;
    font-size: 14px;

    &__empty, &__unavailable {
      text-align: center;
      position: relative;
      top: 50%;
      margin-top: -32px;
      color: $font-color;
    }
  }
}
</style>

<script>
import Documents from './Documents'
import Modules from './Modules'
import { TYPE_MODULE, LABELS } from '../constants'
import { mapActions } from 'vuex'

export default {
  name: "Picker",

  components: {
    'documents': Documents,
    'modules': Modules
  },

  props: {
    pickerType: {
      type: String,
      required: true
    },
    pickerLabels: {
      type: Object,
      required: false
    },
    documents: {
      type: Object,
      required: true,
    },
    images: {
      type: Object,
      required: true,
    },
    allowedStates: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      typeModule: TYPE_MODULE,
      labels: null,
      searchInput: '',
      input: '',
      resultsHeight: 0,
      loading: false,
      noResults: false,
      notAvailable: false,
      previewsSwitchEnabled: false,
    }
  },

  methods: {
    /**
     * @return {void}
     */
    onSearchInput (value) {
      this.onLoading()
      this.input = value
      this.$refs.results.scrollTop = 0
    },

    /**
     * @return {void}
     */
    onLoading () {
      this.loading = true
    },

    /**
     * @return {void}
     */
    stopLoading () {
      this.loading = false
    },

    /**
     * @param {array} results
     * @return {void}
     */
    onUpdate (results) {
      this.stopLoading()

      if (results) {
        this.notAvailable = false
        this.noResults = results.length === 0
      } else {
        this.notAvailable = true
      }
    },

    /**
     * On iframe window resize
     *
     * @returns {void}
     */
    onWindowResize () {
      this.setResultsHeight()
    },

    /**
     * Set new height for results section
     * In this way we can use all the benefits of static height
     *
     * @returns {void}
     */
    setResultsHeight () {
      // 48 - margin 24x2
      const topHeight = this.$refs.top.offsetHeight + 48
      this.resultsHeight = window.innerHeight - topHeight
    },

    ...mapActions({
      onPreviewSwitchChange: 'setPreviewsSwitchEnabled'
    }),
  },

  created () {
    this.onLoading()
    this.labels = this.pickerLabels || LABELS
  },

  mounted () {
    this.setResultsHeight()
    window.addEventListener('resize', this.onWindowResize)
  },

  beforeUnmount () {
    window.removeEventListener('resize', this.onWindowResize)
  }
}
</script>
