<script>
export default {
  methods: {
    /**
     * Post message to picker parent (SC API)
     * @param {string} command
     * @param {string, number, object} argument
     * @returns {void}
     */
    postMessage (command, argument) {
      window.parent.postMessage({
        command: command,
        argument: argument
      }, '*')
    },
  }
}
</script>
