<script>
import Client from '../mixins/Client.vue'

export default {
  mixins: [
    Client
  ],

  methods: {
    /**
     * Fetch image thumbnail from Vault
     *
     * @returns {object}
     */

    async getImageThumbnail (document) {
      let documentImage = { src: '', fallback: false }
      await this.client.post('/request/image-thumbnail', {
        data: {
          id: document.id,
          version: `${document.major_version}/${document.minor_version}`
        }
      })
        .then((response) => {
          documentImage = { src: response.data, fallback: false }
        })
        .catch(() => {
          documentImage = { src: this.getImageThumbnailFallback(document), fallback: true }
        })

      return documentImage
    },

    /**
     * Fetch document thumbnail (static)
     *
     * @returns {string}
     */
    getImageThumbnailFallback (document) {
      const basePath = './thumbnails/'
      const filename = document.filename || null

      if (filename) {
        return basePath + filename.split('.').pop() + '.svg'
      }

      if (document.binder) {
        return basePath + 'binder.svg'
      }

      return basePath + 'blank.svg'
    },
  },
}
</script>
