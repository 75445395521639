<script>
export default {
  data() {
    return {
      debouncer: null
    }
  },

  methods: {
    /**
     * Debounces action for 500ms
     *
     * @returns {void}
     */
    debounce (fn, ms = 500) {
      if (this.debouncer) {
        window.clearTimeout(this.debouncer)
        this.debouncer = null
      }

      this.debouncer = window.setTimeout(() => {
        fn()
        this.debouncer = null
      }, ms)
    }
  }
}
</script>
